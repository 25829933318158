.statistics {
  min-height: 100vh;

  &__wrapper {
    margin: 30px 0 0 0;

    &:last-of-type {
      margin-bottom: 70px;
    }
  }

  &__title {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #1d1617;
  }

  &__content {
    display: flex;
    position: relative;
    left: 50%;
    width: 100vw;
    margin-left: -50vw;
    padding: 0 20px;
    overflow-x: scroll;
    scroll-snap-type: x mandatory;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    .content__training-card {
      display: flex;
      flex-direction: column;
      align-items: center;
      min-width: 60%;
      border-radius: 20px;
      background: linear-gradient(
        274.42deg,
        rgba(146, 163, 253, 0.2) 0%,
        rgba(157, 206, 255, 0.2) 124.45%
      );
      padding: 30px 30px 20px;

      &:not(:last-of-type) {
        margin-right: 15px;
      }

      img {
        height: 100px;
        object-fit: contain;
      }

      .training-card__info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
          font-size: 14px;
          line-height: 18px;
          text-align: center;
          color: #1d1617;
          margin: 15px 0;

          span {
            display: block;
            font-weight: 400;
            font-size: 12px;
            color: #7b6f72;
            margin: 5px 0 0 0;
          }
        }
      }
    }
  }

  &__reviews {
    margin: 0 0 70px 0;
    overflow: unset !important;

    .content__card {
      width: 100%;
      display: flex;
      background: white;
      border-radius: 16px;
      padding: 15px;
      box-shadow: 0px 10px 40px rgba(29, 22, 23, 0.07);

      &:not(:first-of-type) {
        margin: 15px 0 0 0;
      }

      .card__info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 0 0 8px;

        h3 {
          font-weight: 500;
          font-size: 12px;
          line-height: 18px;
          color: #1d1617;
          margin: 0;

          span {
            display: block;
            font-weight: 400;
            color: #7b6f72;
          }
        }

        p {
          font-weight: 400;
          font-size: 10px;
          line-height: 15px;
          color: #a4a9ad;
          margin: 3px 0 0 0;
        }
      }
    }
  }

  .reviews__loading {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: #7b6f72;
    padding: 10px 0;
  }
}
