.anamnese{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__header{
        background-color: transparent;
        color: white;
        width: 100%;
        position: fixed;
        top: 0;
        padding: 20px;

        .head__title{
            color: white;
            margin-left: 10px;
        }

        .head__controls{
            display: flex;
            justify-content: flex-start;
        }
    }

    &__ilustration{
        width: 100%;
        .ilustration_image{
            width: 100%;
            object-fit:cover;
        }
    }

    &__questions{
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
       padding: 10px;
       width: 100%;

       .form__submit-button{
         margin-top: 20px;
       }

       .anamnese__regular_activity{
        display: flex;
    align-items: center;
    width: 100%;
    
    background: #f7f8f8;
    border-radius: 14px;
    padding: 0 15px;
    margin-top: 6px;
       }

       .anamnese__regular_activity_hidden{
        display: none;
      }

        .anamnese__progress {
            display: inline-block;
            text-align: center;
            width: 100px;
            height: 60px;
            line-height: 60px;
            text-transform: uppercase;
            font-family: sans-serif;
            text-decoration: none;
            font-size: 20px;
            transition: 1.5s;
            position: relative;
            background: white;
            border: none;
            
            // background: #96b2fd;
            
          }
          .anamnese__progress svg,
          svg rect {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            fill: transparent;
            // border-radius: 50%;
          }
          .anamnese__progress svg rect {
            stroke: #96b2fd;
            stroke-width: 4;
            transition: all 500ms;
            stroke-dasharray: 340;
            stroke-dashoffset: 340;
          
          }
          .anamnese__progress:hover svg rect {
            stroke-dashoffset: 0;
          }
    }
}